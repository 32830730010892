import { WindupChildren, Pace,Pause } from "windups";

export default function Type() {
  return (
    <WindupChildren>
      <Pace ms={120}>
        {"嗨 别来无恙啊"}
        <Pause ms={1000} />
        <div>现在是2022年12月31日{new Date().toLocaleTimeString()}</div>
        <div>
          距离2023年还有0天
          {(new Date(2023, 0, 1).getHours() - new Date().getHours() + 24) % 24 - 1}
          时{((new Date(2023, 0, 1) - new Date()) / 1000 / 60).toFixed(0) % 60}
          分
        </div>
        <div>2022辛苦了 2023已在路上</div>
        <div>祝协会的各位2023年新年快乐</div>
        <center>
          <img
            style={{margin: "10% 0 0 0",transition: "all 1s ease-in-out"}}
            height={"20%"}
            width={"20%"}
            src="https://zjueva-1312007296.cos.ap-shanghai.myqcloud.com/%E4%BC%9A%E6%A0%87.png"
          />
        </center>
      </Pace>
    </WindupChildren>
  );
}
