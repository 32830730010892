import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Text from "./Text";
import Type from "./Type";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
    {/* <Text/> */}
    <div
      style={{
        fontfamily: " Microsoft JhengHei",
        fontweight: "10",
        color: "white",
        fontSize: "1.5rem",
        zIndex: "1000",
        position: "relative",
        margin: "10% 1%",
        userSelect: "none",
      }}
    >
      <Type />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
