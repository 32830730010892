import { Fireworks } from '@fireworks-js/react'

export default function App() {
  return (
    <Fireworks
      options={{
        hue:{
            min: 0,
            max: 360
        },
        acceleration:1,
        explosion:10,
        gravity:0,
        traceSpeed: 6,
        rocketsPoint: {
          min: 0,
          max: 100
        },
        mouse:{
            click: true,
            move: false,
            max: 1
        },
        lineWidth:{
            explosion: {
                min: 1,
                max: 3
              },
              trace: {
                min: 1,
                max: 2
              }
        }

      }}
      style={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: '#000'
      }}
    />
  )
}
