import React from "react";
import TypeWriterEffect from "react-typewriter-effect";
export default class Text extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sen1: "",
      sen2: "",
      sen3: "",
      sen4: "",
      sen5: "",
      sen6: "",
    };
  }
  componentDidMount() {
    this.setState({
      sen1: "嗨 别来无恙啊",
      sen2: `现在是2022年12月31日${new Date().toLocaleTimeString()}`,
      sen3: `距离2023年还有${(
        (new Date(2023, 0, 1) - new Date()) /
        1000 /
        60 /
        60 /
        24
      ).toFixed(0)}天${(
        (new Date(2023, 0, 1) - new Date()) /
        1000 /
        60 /
        60
      ).toFixed(0)}时${(
        (new Date(2023, 0, 1) - new Date()) /
        1000 /60
      ).toFixed(0)%60}分`,
      sen4: "2022辛苦了 2023已在路上 ",
      sen5: "祝协会的各位2023年新年快乐",
    });
  }
  handleChange(event) {
    this.setState({ text: event.target.value });
  }
  render() {
    return (
      <TypeWriterEffect
        textStyle={{
          //   fontFamily: "YouYuan",
          fontFamily: " Microsoft JhengHei",
          fontweight: "10",
          color: "white",
          fontSize: "2rem",
          zIndex: "1000",
          position: "relative",
          margin: "20% 20% 0 20%",
        }}
        startDelay={100}
        cursorColor="black"
        multiText={[
          this.state.sen1,
          this.state.sen2,
          this.state.sen3,
          this.state.sen4,
          this.state.sen5,
        ]}
        typeSpeed={100}
      />
    );
  }
}
